<template>
  <div class="container">
    <PageTitle class="pageTitle">
      {{ pageTitle }}
    </PageTitle>
    <PlanMenu />
    <div class="content">
      <Balance v-if="isLogin" :point="profile.point" />
      <Trades />
      <UserTransactions v-if="isLogin" />
    </div>
  </div>
</template>

<script>
import PlanMenu from "@/views/toefl/plans/PlanMenu.vue";
import PageTitle from "@/components/PageTitle.vue";
import Balance from "@/components/balance/Balance.vue";
import Trades from "@/views/toefl/views/Trades.vue";
import UserTransactions from "@/views/toefl/views/UserTransactions.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle}: ${this.$t("toefl.T-Coin")} - ` + this.CompanyName
    };
  },
  components: {
    PlanMenu,
    PageTitle,
    Balance,
    Trades,
    UserTransactions
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    profile() {
      return this.$store.getters["user/getProfile"];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 100vw;
  margin: 0px;
  padding: 40px 0px;
  background: #f4f5f8;

  .pageTitle {
    padding-left: 32px !important;
  }
  .content {
    width: calc(100vw - 60px);
    margin: 0px auto;
  }
}
</style>
